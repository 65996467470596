import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import theme from "../styles/theme"

const Stop = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  h2 {
    font-weight: bold;
    font-size: 16px;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    :after {
      display: block;
      content: "";
      width: 100px;
      height: 4px;
      border-radius: 1px;
      background: ${theme.colors.primary.pink};
      margin-top: 26px;
    }
  }
  .subTitle {
    font-size: 13px;
    padding: 26px 0;
    text-align: center;
  }
  .gatsby-image-wrapper {
    margin: 0px 0 16px;
    width: 180px;
  }
  .text {
    padding: 12px 0;
  }
`

const Ssecon = styled.div`
  padding: 16px 0;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  .name {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-end;
    justify-content: center;
    p:last-child {
      font-size: 20px;
      font-weight: bold;
      margin-left: 16px;
    }
  }
  .gatsby-image-wrapper {
    margin: 18px 0;
    width: 200px;
  }
  .figTxt {
    font-size: 12px;
  }
  .carrer {
    padding: 32px 0;
    font-size: 14px;
  }
`

const Sbook = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
  justify-content: center;
  .gatsby-image-wrapper {
    width: 100px;
    margin-right: 10px;
  }
  p {
    font-size: 10px;
  }
`

const WhoWeAre = () => {
  const data = useStaticQuery(query)
  return (
    <>
      <Stop>
        <h2>{data.text.whoWeAre.title}</h2>
        <p className="subTitle">{data.text.whoWeAre.subTitle}</p>
        <StaticImage
          src="../images/computer-3241350_640.jpg"
          alt="プレゼンイメージ"
          placeholder="none"
        />
        <p className="text">{data.text.whoWeAre.organization}</p>
      </Stop>
      <Ssecon>
        <div className="name">
          <p>{data.text.whoWeAre.repre}</p>
          <p>{data.text.whoWeAre.name}</p>
        </div>
        <StaticImage
          src="../images/daihyou.jpg"
          alt="桑野優子"
          placeholder="none"
        />
        <p className="figTxt">{data.text.whoWeAre.career}</p>
        <p className="carrer">{data.text.whoWeAre.career2}</p>
      </Ssecon>
      <Sbook>
        <a
          href="https://www.amazon.co.jp/%E3%83%97%E3%83%AD%E3%81%8C%E6%95%99%E3%81%88%E3%82%8B%E7%8F%BE%E5%A0%B4%E3%81%A7%E5%BD%B9%E7%AB%8B%E3%81%A4%E8%89%B2%E3%81%AE%E4%BD%BF%E3%81%84%E6%96%B9-%E6%98%8E%E6%97%A5%E3%81%8B%E3%82%89%E4%BD%BF%E3%81%88%E3%82%8B%E8%89%B2%E3%81%AE%E3%83%AB%E3%83%BC%E3%83%AB-%E6%A1%91%E9%87%8E%E5%84%AA%E5%AD%90/dp/4908344000/ref=sr_1_1?dchild=1&qid=1625925698&s=books&sr=1-1&text=%E6%A1%91%E9%87%8E%E5%84%AA%E5%AD%90"
          rel="noopener noreferrer"
          target="_blank"
        >
          <StaticImage
            src="../images/book-text.jpg"
            alt="プロが教える現場で役立つ色の使い方　明日から使える色のルール"
            placeholder="none"
          />
        </a>
        <p>{data.text.whoWeAre.books}</p>
      </Sbook>
    </>
  )
}

export const query = graphql`
  {
    text: dataYaml {
      whoWeAre {
        title
        subTitle
        repre
        organization
        name
        career2
        career
        books
      }
    }
  }
`

export default WhoWeAre
