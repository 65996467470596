import React from "react"

export const BorderBottom = props => {
  const styles = {
    background: props.color,
    width: props.width,
    height: props.height,
    content: "",
    display: "block",
    marginTop: "4px",
    borderRadius: "2px",
  }
  return <div style={styles}></div>
}

export const Test = () => {
  return <p>test</p>
}
