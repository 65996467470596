import React from "react"
import styled from "styled-components"
import { SnsCard } from "../molc/SnsCard"

import { FaFacebookSquare } from "react-icons/fa"
import { IconContext } from "react-icons"
import { GrDocumentNotes } from "react-icons/gr"
import { FaTwitterSquare } from "react-icons/fa"
import { RiInstagramFill } from "react-icons/ri"

const Fb = () => (
  <IconContext.Provider value={{ color: "black", size: "40px" }}>
    <FaFacebookSquare />
  </IconContext.Provider>
)
const No = () => (
  <IconContext.Provider value={{ color: "black", size: "40px" }}>
    <GrDocumentNotes />
  </IconContext.Provider>
)
const Tw = () => (
  <IconContext.Provider value={{ color: "black", size: "40px" }}>
    <FaTwitterSquare />
  </IconContext.Provider>
)
const Ig = () => (
  <IconContext.Provider value={{ color: "black", size: "40px" }}>
    <RiInstagramFill />
  </IconContext.Provider>
)

const icons = [<Fb />, <No />, <Tw />, <Ig />]

const Swrap = styled.div`
  max-width: 300px;
  margin: 0 auto;
  padding: 20px 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
`
const Scards = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0;
  p {
    font-size: 12px;
  }
  h5 {
    font-size: 14px;
    font-weight: bold;
    margin: 0.62em 0 0.62em;
  }
`

export const SnsData = props => {
  const { data } = props
  return (
    <Swrap>
      {data.facebook.map((c, i) => (
        <Scards key={i}>
          <a href={c.url}>
            {/* <SnsCard> */}
            {icons[i]}
            {/* <div>
                <h5>{c.title}</h5>
                <p>{c.details}</p>
              </div> */}
            {/* </SnsCard> */}
          </a>
        </Scards>
      ))}
    </Swrap>
  )
}
