import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

const StyledWrap = styled.div`
  padding: 16px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

const StyledCircle = styled.div`
  color: black;
  font-size: 11px;
  text-align: center;
  background: rgba(265, 265, 265, 0.8);
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  border-radius: 150px;
`

const PartsCircleThree = () => {
  const data = useStaticQuery(query)
  return (
    <StyledWrap>
      {data.sens.topContents.circleContents.map((text, index) => (
        <StyledCircle key={index}>{text}</StyledCircle>
      ))}
    </StyledWrap>
  )
}

export const query = graphql`
  {
    sens: dataYaml {
      topContents {
        circleContents
      }
    }
  }
`

export default PartsCircleThree
