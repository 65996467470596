import React from "react"
import styled from "styled-components"

const Swrap = styled.div`
  width: 140px;
  height: 160px;
  background: #fafafa;
  border: 1px solid #dadada;
  padding: 12px;
  display: flex;
  flex-flow: column nowrap;
`

export const SnsCard = props => {
  return <Swrap>{props.children}</Swrap>
}
