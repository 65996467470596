import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const StyledWrap = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  span {
    padding: 0.62em 2.32em;
    font-size: 16px;
    display: inline-block;
    margin-top: 20px;
  }
`

const PartsLinkButton = props => {
  const styled = {
    background: props.color,
    color: "white",
  }
  return (
    <StyledWrap>
      {props.url ? (
        <Link
          to={props.url && props.url}
          style={styled}
          rel={props.url && "noopener noreferrer"}
          target={props.url && "_blank"}
        >
          <span>ENTRY!</span>
        </Link>
      ) : (
        <span style={styled}>ENTRY!</span>
      )}
    </StyledWrap>
  )
}

export default PartsLinkButton
