import React from "react"
import styled from "styled-components"

const StyledWhite = styled.div`
  background: white;
  margin: 8px 18px 18px;
  padding: 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`
export const WhiteSpace = props => {
  return <StyledWhite>{props.children}</StyledWhite>
}
