import React from "react"
import styled from "styled-components"
import theme from "../styles/theme"

const Sdiv = styled.div`
  padding: 26px 0;
  h4 {
    background: ${theme.colors.primary.black};
    color: white;
    font-size: 18px;
    text-align: center;
    width: 100%;
    padding: 9px 0;
  }
  span {
    font-weight: bold;
    font-size: 18px;
    padding: 12px 0;
    display: inline-block;
  }
  ul {
    li {
      margin: 12px 0 12px 18px;
      list-style: disc;
    }
  }

  h6 {
    font-weight: bold;
    width: 100%;
    :after {
      display: block;
      content: "";
      width: 100%;
      height: 5px;
      background: ${theme.colors.primary.pink};
      margin-top: 8px;
    }
  }
`

const Simages = styled.div`
  padding: 16px 0 32px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  h5 {
    width: 60%;
    font-weight: bold;
    font-size: 14px;
  }
  .gatsby-image-wrapper {
    width: 40%;
  }
`

export const FirstSection1 = props => {
  const { title, but, list, message, question, image } = props
  return (
    <Sdiv>
      <h4>{title}</h4>
      <span>{but}</span>
      <ul>
        {list.map((list, index) => {
          return <li key={index}>{list}</li>
        })}
      </ul>
      <Simages>
        <h5>{message}</h5>
        {image}
      </Simages>
      <h6>{question}</h6>
    </Sdiv>
  )
}
