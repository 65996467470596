import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import theme from "../styles/theme"

const Swrapper = styled.div``
const Stop = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  h2 {
    font-size: 18px;
    font-weight: bold;
  }
  .gatsby-image-wrapper {
    margin: 24px 0;
    width: 150px;
  }
  p {
    font-size: 14px;
    line-height: 1.62em;
    .pinkLine {
      text-decoration: ${theme.colors.primary.pink} 3px underline;
    }
  }
`
const Ssecond = styled.div`
  h3 {
    width: 100%;
    text-align: center;
    font-weight: bold;
    margin-top: 40px;
    font-size: 18px;
  }
  h4 {
    width: 100%;
    text-align: center;
    padding: 16px 0 8px;
  }
  .block {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-start;
    margin: 18px 0;
    div {
      h5 {
        font-size: 20px;
        font-weight: bold;
      }
      span {
        display: block;
        content: "";
        width: 160px;
        height: 2px;
        margin: 4px 0;
      }
    }
    span {
      font-size: 34px;
      margin: 0 4px;
      opacity: 0.6;
    }
  }
  .block2 {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-end;
    margin: 18px 0;
    span {
      font-size: 34px;
      margin: 0 4px;
      opacity: 0.6;
    }
    div {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-end;
      h5 {
        font-size: 20px;
        font-weight: bold;
      }
      span {
        margin: 4px 0;
        display: block;
        content: "";
        width: 160px;
        height: 2px;
      }
    }
  }
`

const Sthird = styled.div`
  margin: 32px 0 0;
  p {
    font-size: 16px;
    line-height: 1.32em;
  }
  .gatsby-image-wrapper {
    overflow: hidden;
    height: 100px;
    width: auto;
    margin: 20px 0 0;
  }
  .beforeBtn {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    width: 100%;
    margin-top: 30px;
  }
`

const Reccomend = () => {
  const data = useStaticQuery(query)
  return (
    <Swrapper>
      <Stop>
        <h2>{data.text.contents.title}</h2>
        <StaticImage
          src="../images/conversation.jpg"
          placeholder="none"
          alt="日本カラープランニング協会セミナー"
        />
        <p>
          <span>{data.text.contents.subtitle[0]}</span>
          <span className="pinkLine">{data.text.contents.subtitle[1]}</span>
          <span>{data.text.contents.subtitle[2]}</span>
        </p>
      </Stop>
      <Ssecond>
        <h3>CONTENTS</h3>
        <h4 style={{ color: `${theme.colors.primary.green}` }}>
          {data.text.contents.mokuji[0]}
        </h4>
        {data.text.contents.data1.map((text, index) =>
          index % 2 === 0 ? (
            <div key={index} className="block">
              <span style={{ color: `${theme.colors.primary.green}` }}>
                {text.num}
              </span>
              <div>
                <h5>{text.main}</h5>
                <span
                  style={{ background: `${theme.colors.primary.green}` }}
                ></span>
                <p>{text.sub}</p>
              </div>
            </div>
          ) : (
            <div key={index} className="block2">
              <div>
                <h5>{text.main}</h5>
                <span
                  style={{ background: `${theme.colors.primary.green}` }}
                ></span>
                <p>{text.sub}</p>
              </div>
              <span style={{ color: `${theme.colors.primary.green}` }}>
                {text.num}
              </span>
            </div>
          )
        )}
        <h4 style={{ color: `${theme.colors.primary.yellow}` }}>
          {data.text.contents.mokuji[1]}
        </h4>
        {data.text.contents.data2.map((text, index) =>
          index % 2 === 0 ? (
            <div key={index} className="block">
              <span style={{ color: `${theme.colors.primary.yellow}` }}>
                {text.num}
              </span>
              <div>
                <h5>{text.main}</h5>
                <span
                  style={{ background: `${theme.colors.primary.yellow}` }}
                ></span>
                <p>{text.sub}</p>
              </div>
            </div>
          ) : (
            <div key={index} className="block2">
              <div>
                <h5>{text.main}</h5>
                <span
                  style={{ background: `${theme.colors.primary.yellow}` }}
                ></span>
                <p>{text.sub}</p>
              </div>
              <span style={{ color: `${theme.colors.primary.yellow}` }}>
                {text.num}
              </span>
            </div>
          )
        )}
      </Ssecond>
      <Sthird>
        <p>{data.text.contents.lastSentence}</p>
        <StaticImage
          src="../images/knshutop.jpg"
          placeholder="none"
          alt="日本カラープランニング協会セミナー"
        />
        <p className="beforeBtn">{data.text.contents.entryBtn}</p>
      </Sthird>
    </Swrapper>
  )
}

export const query = graphql`
  {
    text: dataYaml {
      contents {
        title
        subtitle
        mokuji
        secondTitle
        lastSentence
        data1 {
          main
          num
          sub
        }
        data2 {
          sub
          num
          main
        }
        entryBtn
      }
    }
  }
`

export default Reccomend
