import React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

const text = [
  " リーフレットやチラシ",
  "ウェブサイト",
  "パッケージデザイン",
  "プレゼン資料",
  "ブランディング・CI",
]

// const images = [
//   '../images/color_icons_leaflet.png',
//   '../images/color_icons_websites.png',
//   '../images/color_icons_package.png',
//   '../images/color_icons_presentation.png',
//   '../images/brandicon.png'
// ]

const StyledDiv = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: space-around;
  padding: 12px 0;
`
const StyledParts = styled.div`
  width: 28%;
  margin-top: 20px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  .gatsby-image-wrapper {
    height: auto;
    img {
      height: auto;
      width: 80%;
      margin-left: 10%;
    }
  }
`

const FiveIllust = () => {
  return (
    <StyledDiv>
      <StyledParts>
        <StaticImage
          placeholder="blurred"
          src="../images/color_icons_leaflet.png"
          alt="アイコン：日本カラープランニング協会"
        />
        <p>{text[0]}</p>
      </StyledParts>
      <StyledParts>
        <StaticImage
          placeholder="blurred"
          src="../images/color_icons_websites.png"
          alt="アイコン：日本カラープランニング協会"
        />
        <p>{text[1]}</p>
      </StyledParts>
      <StyledParts>
        <StaticImage
          placeholder="blurred"
          src="../images/color_icons_package.png"
          alt="アイコン：日本カラープランニング協会"
        />
        <p>{text[2]}</p>
      </StyledParts>
      <StyledParts>
        <StaticImage
          placeholder="blurred"
          src="../images/color_icons-presentation.png"
          alt="アイコン：日本カラープランニング協会"
        />
        <p>{text[3]}</p>
      </StyledParts>
      <StyledParts>
        <StaticImage
          placeholder="blurred"
          src="../images/brandicon.png"
          alt="アイコン：日本カラープランニング協会"
        />
        <p>{text[4]}</p>
      </StyledParts>
    </StyledDiv>
  )
}

export default FiveIllust
