import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

import PartsCircleThree from "../components/PartsCircleThree"
import { BorderBottom } from "../components/css-parts"
import theme from "../styles/theme"

const StyledWrap = styled.div`
  position: relative;
  height: 60vh;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  color: white;
  z-index: 1;
  p {
    font-size: 13px;
  }
  h2 {
    font-size: 11px;
    margin-top: 22px;
    margin-bottom: 5px;
  }
  h1 {
    font-size: 20px;
  }
`

const StyledBack = styled.div`
  position: absolute;
  z-index: -3;
  height: 60vh;
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  :after {
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    position: absolute;
    top: 0;
    left: 0;
  }
`

const MainVisualSection = props => {
  const data = useStaticQuery(query)
  return (
    <StyledWrap>
      <StyledBack>
        <StaticImage
          src="../images/color-seminor.jpg"
          alt="オンラインセミナーの画像"
          placeholder="blurred"
        />
      </StyledBack>
      <p>{data.sens.topContents.catches[0]}</p>
      <h2>{data.sens.topContents.catches[1]}</h2>
      <h1>
        {data.sens.topContents.catches[2]}
        <BorderBottom
          color={theme.colors.primary.pink}
          width="62px"
          height="5px"
        />
      </h1>
      <PartsCircleThree />
      {props.children}
    </StyledWrap>
  )
}

export const query = graphql`
  {
    sens: dataYaml {
      topContents {
        catches
        circleContents
      }
    }
  }
`

export default MainVisualSection
