import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { UnderLineTitle } from "../atom/UnderLineTitle"
import styled from "styled-components"

import { FaLine } from "react-icons/fa"
import { CgWebsite } from "react-icons/cg"
import { IconContext } from "react-icons"
import { SnsCard } from "../molc/SnsCard"
import { SnsData } from "./SnsData"

const Line = () => (
  <IconContext.Provider value={{ color: "black", size: "40px" }}>
    <FaLine />
  </IconContext.Provider>
)
const Web = () => (
  <IconContext.Provider value={{ color: "black", size: "40px" }}>
    <CgWebsite />
  </IconContext.Provider>
)

const Sdiv = styled.div`
  display: flex;
  margin: 0 auto;
  max-width: 320px;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0;
  p {
    font-size: 12px;
  }
  h5 {
    font-size: 14px;
    font-weight: bold;
    margin: 0.62em 0 0.62em;
  }
`

export const ContactSection = () => {
  const data = useStaticQuery(query)
  return (
    <>
      <UnderLineTitle title={data.text.contact.jpTitle} />
      <Sdiv>
        <a href={data.text.contact.contactIcons.web[2]}>
          <SnsCard>
            <Web />
            <div>
              <h5>{data.text.contact.contactIcons.web[0]}</h5>
              <p>{data.text.contact.contactIcons.web[1]}</p>
            </div>
          </SnsCard>
        </a>
        <a href={data.text.contact.contactIcons.line[2]}>
          <SnsCard>
            <Line />
            <div>
              <h5>{data.text.contact.contactIcons.line[0]}</h5>
              <p>{data.text.contact.contactIcons.line[1]}</p>
            </div>
          </SnsCard>
        </a>
      </Sdiv>
      <SnsData data={data.text.contact.snsIcons} />
    </>
  )
}

export const query = graphql`
  {
    text: dataYaml {
      contact {
        jpTitle
        contactIcons {
          web
          line
        }
        snsIcons {
          title
          facebook {
            title
            details
            url
          }
        }
      }
    }
  }
`
