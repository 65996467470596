import React from "react"
import styled from "styled-components"
import theme from "../../styles/theme"

const Sh3 = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: 100%;
  text-align: center;
  font-weight: bold;
  &:after {
    display: block;
    content: "";
    width: 70px;
    height: 4px;
    border-radius: 4px;
    margin-top: 0.62em;
    background: ${theme.colors.primary.green};
  }
`

export const UnderLineTitle = props => {
  const { title } = props
  return (
    <Sh3>
      {title.map((c, i) => (
        <span key={i}>{c}</span>
      ))}
    </Sh3>
  )
}
