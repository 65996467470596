import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
// import { CourseOrg } from './org/CourseOrg'

const S1st = styled.div`
  width: 100%;
  h2 {
    font-size: 18px;
    font-weight: bold;
  }
  p {
    font-size: 14px;
    line-height: 1.32em;
    padding: 14px 0 28px;
  }
`

const TrailSection = props => {
  const data = useStaticQuery(query)
  return (
    <>
      <S1st>
        <h2>{data.text.trailCourse.title}</h2>
        <p>{data.text.trailCourse.subtitle}</p>
      </S1st>
      <div>
        <div style={{ padding: "55% 0 0 0", position: "relative" }}>
          <iframe
            src="https://player.vimeo.com/video/691257698?h=73f3b626a9&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
            title="カラープランニング30分無料体験コース.mov"
          />
        </div>
        <script src="https://player.vimeo.com/api/player.js" />
      </div>
      {/* <CourseOrg
        data={data.text.trailCourse.freeTrial}
      /> */}
      {/* <CourseOrg
        data={data.text.trailCourse.payTrial}
      /> */}
      {props.children}
    </>
  )
}

export const query = graphql`
  {
    text: dataYaml {
      trailCourse {
        title
        subtitle
        freeTrial {
          title
          schedule {
            title
            date {
              day
              week
              hour
            }
          }
          details
        }
        payTrial {
          title
          schedule {
            title
            date {
              day
              week
              hour
            }
          }
          details
        }
      }
    }
  }
`

export default TrailSection
