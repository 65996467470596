import React from "react"
import Layout from "../components/Layout"
import MainBody from "../components/MainBody"
import Seo from "../components/SEO"

const IndexPage = () => {
  // const [isFBiconActive, setIsFBiconActive] = useState(null)

  return (
    <Layout>
      <Seo />
      <MainBody />
    </Layout>
  )
}

export default IndexPage
