import React from "react"
import styled, { css } from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import FiveIllust from "../components/FiveIllust"
import { StaticImage } from "gatsby-plugin-image"

import PartsLinkButton from "./PartsLinkButton"
import { FirstSection1 } from "./FirstSection1"
import { WhiteSpace } from "./SectionsDesign"
import TrailSection from "./TrailSection"
import WhoWeAre from "./WhoWeAre"
import Reccomend from "./Reccomend"
import theme from "../styles/theme"
import MainVisualSection from "./MainVisualSection"

//scroll
import { Element } from "react-scroll"
import { Link as ScrollLink } from "react-scroll"
import { media } from "../styles/media"
import { ContactSection } from "./org/ContactSection"
const att = {
  activeClass: "active",
  offset: -30,
  duration: 500,
}

const Swrap = styled.div`
  ${() =>
    media.pc(css`
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      background: linear-gradient(
        186deg,
        rgba(0, 210, 190, 0.5) 15%,
        rgba(67, 150, 150, 0.8) 89%
      );
    `)}
`

const StyledDiv = styled.div`
  ${() =>
    media.pc(css`
      max-width: 500px;
    `)}
  width:100%;
  background: #e5e5e5;
  padding: 0 0 30px;
  h2 {
    width: 100%;
    text-align: center;
  }
`

const MainBody = () => {
  const data = useStaticQuery(query)
  return (
    <Swrap>
      <StyledDiv>
        <MainVisualSection>
          <ScrollLink smooth="true" to="apply" {...att}>
            <PartsLinkButton color={theme.colors.primary.yellow} />
          </ScrollLink>
        </MainVisualSection>
        <div style={{ paddingTop: "32px" }}>
          <h2>{data.text.sectionHeader[0]}</h2>
          <WhiteSpace>
            <h3 style={{ fontWeight: "bold" }}>
              色使いで悩むこと、ありませんか？{" "}
            </h3>
            <FiveIllust />
            <FirstSection1
              title={data.text.colorUsers.title}
              but={data.text.colorUsers.but}
              list={data.text.colorUsers.list}
              message={data.text.colorUsers.message}
              question={data.text.colorUsers.question}
              image={
                <StaticImage
                  src="../images/manColor.png"
                  alt="色に悩む画像"
                  placeholder="none"
                />
              }
            />
            <FirstSection1
              title={data.text.colorBeginners.title}
              but={data.text.colorBeginners.but}
              list={data.text.colorBeginners.list}
              message={data.text.colorBeginners.message}
              question={data.text.colorBeginners.question}
              image={
                <StaticImage
                  src="../images/womanColor.png"
                  alt="キャリアアップの画像"
                  placeholder="none"
                />
              }
            />
          </WhiteSpace>
        </div>
      </StyledDiv>
      <StyledDiv>
        <div>
          <h2>{data.text.sectionHeader[1]}</h2>
          <WhiteSpace>
            <WhoWeAre />
          </WhiteSpace>
        </div>
      </StyledDiv>
      <StyledDiv>
        <div>
          <h2>{data.text.sectionHeader[2]}</h2>
          <WhiteSpace>
            <Reccomend />
            <ScrollLink
              to="online"
              smooth="true"
              {...att}
              data-online="onlineShortcut"
            >
              <PartsLinkButton color={theme.colors.primary.yellow} />
            </ScrollLink>
          </WhiteSpace>
        </div>
      </StyledDiv>
      <StyledDiv>
        <div>
          <h2>{data.text.sectionHeader[3]}</h2>
          <WhiteSpace>
            <Element name="online" />
            <TrailSection />
            <ScrollLink
              to="apply"
              smooth="true"
              {...att}
              data-apply="applyShortCut"
            >
              <PartsLinkButton color={theme.colors.primary.green} />
            </ScrollLink>
          </WhiteSpace>
        </div>
      </StyledDiv>
      <StyledDiv>
        <div>
          <Element name="apply" />
          <h2>{data.text.sectionHeader[4]}</h2>
          <WhiteSpace>
            <ContactSection />
          </WhiteSpace>
        </div>
      </StyledDiv>
    </Swrap>
  )
}

export const query = graphql`
  {
    text: dataYaml {
      sectionHeader
      colorUsers {
        but
        list
        message
        question
        title
      }
      colorBeginners {
        but
        list
        message
        question
        title
      }
    }
  }
`

export default MainBody
